import { gql } from "@apollo/client";

export const QUERY_GET_PAGINATED_LEAD_LIST = gql`
  query getInterestedInvestors(
    $take: Int
    $skip: Int
    $startDate: String!
    $endDate: String!
  ) {
    getInterestedInvestors(
      paginationOptions: { take: $take, skip: $skip }
      startDate: $startDate
      endDate: $endDate
    ) {
      total
      items {
        id
        email
        fullName
        phoneNumber
        createdAt
        utm_id
        utm_campaign
        utm_medium
        utm_source
        utm_content
        utm_term
        intercomLeadConversationId
      }
    }
  }
`;

export const QUERY_GET_ALL_LEAD_LIST = gql`
  query getInterestedInvestors($startDate: String!, $endDate: String!) {
    getUnPaginatedInterestedInvestors(startDate: $startDate, endDate: $endDate) {
      total
      items {
        id
        email
        fullName
        phoneNumber
        createdAt
        utm_id
        utm_campaign
        utm_medium
        utm_source
        utm_content
        utm_term
        intercomLeadConversationId
      }
    }
  }
`;
