import { useLazyQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PropertyPledge, RoleAction, RoleModule, Transaction, User } from '../../__generated__/graphql';
import { PaginationBar } from '../../components/pagination/pagination.component';
import { Sidebar } from '../../components/sidebar/sidebar';
import { Tab } from '../../components/tab/tab';
import { QUERY_GET_DEPOSIT_LIST, QUERY_GET_INVESTMENT_LIST } from '../../graphql/queries/get-transactions';
import { PaginatedData } from '../../interfaces/pagination.interface';
import { AuthService } from '../../services/auth.service';
import { useAuthAndErrorHandling } from '../../utils/invalid-token.util';
import { TransactionDepositTable } from './tables/deposit-table';
import { TransactionInvestmentTable } from './tables/investment-table';
import { toCurrencyDisplay } from '../../utils/currency.util';
import { endOfMonth, startOfMonth } from 'date-fns';

const take = 10;

interface TransactionTotal extends Transaction {
  sumTotal: number;
}

interface TransactionPaginatedData<T> extends PaginatedData<T> {
  sumTotal: number;
}


export function TransactionListPage() {
  const tabs = useRef([
    {
      name: 'Deposits',
      action: RoleAction.List,
    },
    {
      name: 'Investments',
      action: RoleAction.List,
    },
  ]);

  const navigate = useNavigate();

  const [getDeposits, { loading: depositsLoading, error: depositError, data: depositData }] =
    useLazyQuery<{ deposits: TransactionPaginatedData<TransactionTotal> }>(QUERY_GET_DEPOSIT_LIST, { errorPolicy: 'all', fetchPolicy: 'no-cache' });
  const [getInvestments, { loading: investmentsLoading, error: investmentError, data: investmentData }] =
    useLazyQuery<{ investments: TransactionPaginatedData<PropertyPledge> }>(QUERY_GET_INVESTMENT_LIST, { errorPolicy: 'all', fetchPolicy: 'no-cache' });
  const [selectedTabItem, setSelectedTabItem] = useState('Deposits');
  const [skip, setSkip] = useState(0);
  const today = new Date();
  const [startDate, setStartDate] = useState(startOfMonth(today).toISOString());
  const [endDate, setEndDate] = useState(endOfMonth(today).toISOString());

  const [data, setData] = useState<PaginatedData<TransactionTotal | PropertyPledge>>();

  useAuthAndErrorHandling(depositError);
  useAuthAndErrorHandling(investmentError);

  useEffect(() => {
    if (!AuthService.authenticate(navigate)) {
      return;
    }
  }, []);

  useEffect(() => {
    if (selectedTabItem === 'Deposits') {
      setData(depositData?.deposits);
    } else {
      setData(investmentData?.investments);
    }
  }, [depositData, investmentData]);

  useEffect(() => {
    refresh()
  }, [selectedTabItem, skip, startDate, endDate]);

  function refresh() {
    if (selectedTabItem === 'Deposits') {
      getDeposits({ variables: { take, skip, startDate, endDate } });
    } else {
      getInvestments({ variables: { take, skip, startDate, endDate } });
    }
  }

  function handleTabItemClick(tabItem: string) {
    setSelectedTabItem(tabItem);
  }

  function getTabList() {
    return tabs.current
      .filter(tab => AuthService.hasRole(RoleModule.Transaction, tab.action))
      .map(tab => tab.name);
  }

  const handleDateChange = (take: number, skip: number, startDate: string, endDate: string) => {
    setSkip(skip);
    setStartDate(startDate);
    setEndDate(endDate);
  }

  return (
    <>
      <Sidebar />

      <section className='content full properties'>
        <h1 className='mb-4 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight'>Transactions</h1>

        <div className="flex flex-col-reverse items-center mb-2 md:flex-row md:justify-between">
          <Tab
            items={getTabList()}
            selectedTabItem={selectedTabItem}
            loading={depositsLoading || investmentsLoading}
            handleTabItemClick={handleTabItemClick}
          />
          <div className="flex flex-col items-center justify-center p-6 transition-all duration-300 bg-white rounded-lg shadow md:mt-0">
            {selectedTabItem === 'Deposits' &&
              <div className="font-semibold text-gray-500">
                Total Deposits
                <p className="pt-1 text-2xl font-bold text-pn-blue">
                  {toCurrencyDisplay(depositData?.deposits?.sumTotal as number) || toCurrencyDisplay(0)}
                </p>
              </div>
            }
            {selectedTabItem === 'Investments' &&
              <div className="font-semibold text-gray-500">
                Total Investments
                <p className="pt-1 text-2xl font-bold text-pn-blue">
                  {toCurrencyDisplay(investmentData?.investments.sumTotal as number) || toCurrencyDisplay(0)}
                </p>
              </div>
            }
          </div>
        </div>

        <PaginationBar
          take={take}
          total={data?.total || 0}
          currentTotal={data?.items.length || 0}
          onDateChange={handleDateChange}
        />

        {selectedTabItem === 'Deposits' && <TransactionDepositTable data={data as PaginatedData<Transaction>} loading={depositsLoading} />}
        {selectedTabItem === 'Investments' && <TransactionInvestmentTable data={data as PaginatedData<Transaction>} loading={investmentsLoading} />}
      </section>

    </>
  )
}
